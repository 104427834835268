import React,{useEffect,useState} from "react"
import axios from "axios"

function App() {
  const [data,setdata] = useState([])
  useEffect(() => {
    axios.get("https://backmir.mirlar.uz/products/")
    .then((res) => {
      setdata(res.data)
    })

  },[])
  console.log(data)
  return (
  <>
  {
    data.map((e) => {
      return(
        <div>
          <div>{e.name}</div>
          <div><img src={e.posterdekor}/></div>
        </div>
      )
    })
  }
  </>
  );
}

export default App;
